import "./header.scss"
import {CgScrollV} from 'react-icons/cg'
import {DiCssTricks} from 'react-icons/di'
import Image from "../assets/create.jpg"


const Header = () => {
    return (
        <header className="header flex">
            <DiCssTricks className="header__icon-background"/>
            <DiCssTricks className="header__icon-background"/>
            <DiCssTricks className="header__icon-background"/>
            <div className="header__main flex" data-aos="fade-up">
                <div className="header__section-content">
                    <h1 className="header__title">Projektowanie stron internetowych</h1>
                    <p className="header__text">Buduję responsywne strony internetowe, 
                    aplikacje internetowe i Landing Pages. Wszystkie zrealizowane projekty są zgodne z SEO i Mobile First!</p>
                    <a href="#portfolio"><button className="header__btn-main">Sprawdż portfolio</button></a>
                </div>
                <img src={Image} className="header__img" />
            </div>
                <CgScrollV className="header__icon"/>
        </header>
    );
}
 
export default Header;