import ProactiveNr4 from "../assets/proactive4-portfolio.png"
import ProactiveNr3 from "../assets/portfolio-proactive3.png"
import ProactiveNr2 from "../assets/proactive-porftolio2.png"
import ProactiveNr1 from "../assets/proactive-porftolio1.png"
import Fourmax from "../assets/4max-portfolio1.png"
import Epv from "../assets/epv-portfolio1.png"
import Kupc from "../assets/kupc-portfolio1.png"
import WeatherApp from "../assets/weather-app.png"
import QuizApp from "../assets/quiz-app.png"
import MoviesApp from "../assets/movies-app.png"
import CurrencyApp from "../assets/currency-app.png"
import CalculatorApp from "../assets/calculator-app.png"

const projects = [{
        id: 12,
        name: 'Movies App',
        technology: 'SCSS, React, API',
        category: 'app',
        img: MoviesApp,
        url: 'https://search-movies-engine.netlify.app/'
    },
    {
        id: 11,
        name: 'Program dietetyczny (v4)',
        technology: 'SCSS, React',
        category: 'landing',
        img: ProactiveNr4,
        url: 'https://proactivenutrition.pl/lp/program-dietetyczny-proactive-2/'
    },
    {
        id: 10,
        name: 'Program dietetyczny (v3)',
        technology: 'SCSS, React',
        category: 'landing',
        img: ProactiveNr3,
        url: 'https://proactivenutrition.pl/lp/program-dietetyczny-proactive/'
    },
    {
        id: 9,
        name: 'Quiz App',
        technology: 'SCSS, React',
        category: 'app',
        img: QuizApp,
        url: 'https://websitesdesign.pl/quiz-react/'
    },
    {
        id: 8,
        name: 'Weather App',
        technology: 'SCSS, React, API',
        category: 'app',
        img: WeatherApp,
        url: 'https://websitesdesign.pl/weather-app/'
    },
    {
        id: 7,
        name: 'Program dietetyczny (v2)',
        technology: 'HTML, CSS, JavaScript',
        category: 'landing',
        img: ProactiveNr2,
        url: 'https://proactivenutrition.pl/lp/program-dietetyczny/'
    },
    {
        id: 6,
        name: 'Currency App',
        technology: 'CSS, JavaScript, API',
        category: 'app',
        img: CurrencyApp,
        url: 'https://websitesdesign.pl/currency-converter/'
    },
    {
        id: 5,
        name: 'Program dietetyczny (v1)',
        technology: 'HTML, CSS, JavaScript',
        category: 'landing',
        img: ProactiveNr1,
        url: 'https://proactivenutrition.pl/lp/diet/'
    },
    {
        id: 4,
        name: 'Calculator App',
        technology: 'SCSS, React',
        category: 'app',
        img: CalculatorApp,
        url: 'https://websitesdesign.pl/calculator/'
    },
    {
        id: 3,
        name: 'EPV',
        technology: 'WordPress',
        category: 'company',
        img: Epv,
        url: 'https://epvholding.pl/'
    },
    {
        id: 2,
        name: 'KUPC',
        technology: 'WordPress',
        category: 'company',
        img: Kupc,
        url: 'https://kupc.eu/'
    },
    {
        id: 1,
        name: '4MAX Consulting',
        technology: 'WordPress',
        category: 'company',
        img: Fourmax,
        url: 'https://4maxconsulting.pl/'
    },
]

export default projects