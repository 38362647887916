import "./footer.scss";

const Footer = () => {
    const year = new Date()
    return (
        <footer className="footer">
            <p className="footer__text">Wszelkie prawa zastrzeżone © {year.getFullYear()}</p>
        </footer>
    );
}
 
export default Footer;