import "./portfolio.scss";
import { useState } from "react";
import projects from "./Projects"

const Portfolio = ({refPortfolio}) => {
    const specificProject = projects
    const [currentProject, setCurrentProject] = useState(projects)
    const [currentBtn, setCurrentBtn] = useState('all')

    const handleCategory = (e) => {
        if (e.target.dataset.id == 'landing' || e.target.dataset.id == 'company' || e.target.dataset.id == 'app') {
            setCurrentBtn(e.target.dataset.id)
            const targetProject = specificProject.filter(project => project.category === e.target.dataset.id)
            setCurrentProject(targetProject)
        }
        else if(e.target.dataset.id == 'all') {
            setCurrentBtn(e.target.dataset.id)
            setCurrentProject(specificProject)
        }
    }

    return (
        <div className="portfolio">
            <h2 className="portfolio__title">Portfolio</h2>
            <div className="portfolio__categories-area flex">
                <button className={currentBtn === 'all' ? "portfolio__category-btn active" : "portfolio__category-btn"} 
                data-id="all" onClick={handleCategory}>Wszystko</button>
                <button className={currentBtn === 'landing' ? "portfolio__category-btn active" : "portfolio__category-btn"} 
                data-id="landing" onClick={handleCategory}>Landing Pages</button>
                <button className={currentBtn === 'company' ? "portfolio__category-btn active" : "portfolio__category-btn"} 
                data-id="company" onClick={handleCategory}>Strony firmowe</button>
                <button className={currentBtn === 'app' ? "portfolio__category-btn active" : "portfolio__category-btn"} 
                data-id="app" onClick={handleCategory}>Aplikacje</button>
            </div>
            <div className="portfolio__projects-area grid wrapper" ref={refPortfolio} id="portfolio" data-aos="fade-up">
                {currentProject.map(project => {
                    const {id, name, technology, category, img, url} = project
                    return (
                        <a href={url} rel="nofollow noreferrer" target="a_blank" key={id}>
                            <div className="portfolio__project" category={category}>
                                    <img src={img} className="portfolio__img" alt={name}/>
                                    <div className="portfolio__info">
                                        <p className="portfolio__info-title">{name}</p>
                                        <p className="portfolio__info-tech">{technology}</p>
                                    </div>
                            </div>
                        </a>
                    )
                })}
            </div>
        </div>
    );
}
 
export default Portfolio;