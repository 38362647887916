import "./about.scss";
import Dominik from "../assets/dominik.png"

const About = ({refAbout}) => {
    return (
        <div className="about" id="about" ref={refAbout}>
            <h2 className="about__h3-title">O mnie</h2>
            <div className="about__personal grid wrapper" data-aos="fade-up">
                <div className="about__img-area"><img src={Dominik} alt="dominik" className="about__img"/></div>
                <div className="about__description-area">
                    <h3 className="about__name">Dominik Brzuszczyński</h3>
                    <p className="about__spec">Front-End Development & Digital Marketing</p>
                    <p className="about__bio">Nazywam się Dominik i od 2014 roku zajmuje się szeroko pojętym marketingiem internetowym. Od 2021 postanowiłem kszałcić się w kierunku Front End Web Developmentu. Obecnie tworzę strony internetowe oraz aplikacje w JavaScript i React.
                    <br/><br/>Jeśli uważasz, że nasza wspłópraca mogłaby być owocna, to napisz do mnie korzystając z formularza kontaktowego. 
                    </p>
                    <a href="#contact" className="about__link"><button className="about__btn">Napisz do mnie</button></a>
                </div>
            </div>
        </div>
    );
}
 
export default About;