import {SiJavascript, SiReact, SiTypescript, SiCss3, SiSass, SiWordpress} from "react-icons/si";
import "./technology.scss";

const Technology = ({refTech}) => {
    return (
        <>
        <div className="tech" id="technology" ref={refTech}>
        <h2 className="tech__h3-title">Technolgie</h2>
            <div className="tech__cart-area grid wrapper" data-aos="fade-up">
                <div className="tech__cart flex">
                    <SiCss3 className="tech__icon"/>
                    <h3 className="tech__title">CSS</h3>
                </div>
                <div className="tech__cart flex">
                    <SiSass className="tech__icon"/>
                    <h3 className="tech__title">SASS</h3>
                </div>
                <div className="tech__cart flex">
                    <SiWordpress className="tech__icon"/>
                    <h3 className="tech__title">WordPress</h3>
                </div>
                <div className="tech__cart flex">
                    <SiJavascript className="tech__icon"/>
                    <h3 className="tech__title">JavaScript</h3>
                </div>
                <div className="tech__cart flex">
                    <SiReact className="tech__icon"/>
                    <h3 className="tech__title">React</h3>
                </div>
                <div className="tech__cart flex">
                    <SiTypescript className="tech__icon"/>
                    <h3 className="tech__title">TypeScript</h3>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default Technology;