import Navbar from "./components/Navbar";
import MobileMenu from "./components/MobileMenu";
import Header from "./components/Header";
import Technology from "./components/Technology";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import React, { useState, useEffect, useRef } from "react";
import AOS from "aos"
import "aos/dist/aos.css";

function App() {
  const [openMenu, setOpenMenu] = useState(false);

  const refAbout = useRef()
  const refTech = useRef()
  const refPortfolio = useRef()
  const refContact = useRef()

  const [visibleAbout, setVisibleAbout] = useState(false)
  const [visibleTech, setVisibleTech] = useState(false)
  const [visiblePortfolio, setVisiblePortfolio] = useState(false)
  const [visibleContact, setVisibleContact] = useState(false)

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: .7,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      setVisibleAbout(entry.isIntersecting)
    }, options)
    if(refAbout.current) observer.observe(refAbout.current)
    return () => {
      if(refAbout.current) observer.unobserve(refAbout.current)
    }
  },[])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisiblePortfolio(false)
        setVisibleTech(entry.isIntersecting)
      })
    }, options)
    if(refTech.current)observer.observe(refTech.current)
    return () => {
      if(refTech.current)observer.unobserve(refTech.current)
    }
  },[])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisiblePortfolio(entry.isIntersecting)
      })
    }, options)
    if(refPortfolio.current)observer.observe(refPortfolio.current)
    return () => {
      if(refPortfolio.current)observer.unobserve(refPortfolio.current)
    }
  },[])

  useEffect(() => {
    const observer= new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisibleContact(entry.isIntersecting)
      })
    }, options)
    if(refContact.current)observer.observe(refContact.current)
    return () => {
      if(refContact.current)observer.unobserve(refContact.current)
    }
  },[])


  useEffect(() => {
    AOS.init({
        disable: false, 
        startEvent: 'DOMContentLoaded', 
        initClassName: 'aos-init', 
        animatedClassName: 'aos-animate', 
        useClassNames: true, 
        disableMutationObserver: false, 
        debounceDelay: 50, 
        throttleDelay: 99,  
        offset: 120, 
        delay: 60, 
        duration: 500, 
        easing: 'ease-in-out',
        once: true, 
        mirror: false, 
        anchorPlacement: 'top-center', 
      
      });
},[])

  return (
    <>
    <Navbar 
    openMenu={openMenu} 
    setOpenMenu={setOpenMenu} 
    visibleAbout={visibleAbout}
    visibleTech={visibleTech}
    visiblePortfolio={visiblePortfolio}
    visibleContact={visibleContact}/>
    <MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu}/>
    <Header/>
    <About refAbout={refAbout}/>
    <Technology refTech={refTech}/>
    <Portfolio refPortfolio={refPortfolio}/>
    <Contact refContact={refContact}/>
    <Footer/>
    </>
  );
}

export default App;
