import "./contact.scss";
import {AiOutlineMail, AiFillGithub, AiFillLinkedin} from "react-icons/ai"
import {useState, useRef} from "react";
import emailjs from '@emailjs/browser';

const Contact = ({refContact}) => {
    const [statusMsg, setStatusMsg] = useState(false)
    const contactForm = useRef()

    const handleSendMsg = (e) => {
        const inputs = document.querySelectorAll('.contact__input');
        const textArea = document.querySelector('.contact__msg')
        if(inputs[0].value != '' && inputs[1].value != '' && inputs[2].value != '' && textArea.value != '') {
            e.preventDefault()
            emailjs.sendForm(
            'xyrodill-mail', 
            'template_inpk32h', 
            contactForm.current, 
            'user_BfesER7wPR97Pgqgzz2LG')
            .then((result) => {
          console.log(result.text);
            }, (error) => {
          console.log(error.text);
            });
            setStatusMsg(true)
            setTimeout(() => {
                setStatusMsg(false)
            }, 5000);
        }
    }

    return (
        <div className="contact" id="contact" ref={refContact}>
            <h2 className="contact__title">Skontaktuj się ze mną!</h2>
            <div className="contact__area grid wrapper">
                <form className="contact__form flex" action="mail.php" method="post" ref={contactForm}>
                    <input type="text" placeholder="Imię i nazwisko ..." className="contact__input" name="name" required/>
                    <input type="email" placeholder="Adres e-mail ..." className="contact__input" name="email" required/>
                    <input type="text" placeholder="Temat wiadomości ..." className="contact__input" name="subject" required/>
                    <textarea placeholder="Treść ..." className="contact__msg" type="msg" rows="5" name="message" required/>
                    <button className="contact__btn" onClick={handleSendMsg}>Wyślij wiadomość</button>
                    <span className={statusMsg? "contact__status contact__status--done": "contact__status"}>
                        {statusMsg ? 'Wiadomość została wysłana' : null}
                    </span>
                </form>
                <div className="contact__info">
                    <h3 className="contact__title-info">Pozostańmy w kontakcie</h3>
                    <div className="contact__text-area"><p className="contact__text">Użyj formularza kontaktowego 
                    lub skontaktuj się ze mną bezpośrednio poprzez Linkedin'a lub maila!</p>
                    <div className="contact__social-media">
                        <div className="contact__social-section"><AiOutlineMail className="contact__icon"/>
                            <a href="mailto:d.brzuszczynski@gmail.com" className="contact__link">
                            <span className="contact__social-text">d.brzuszczynski@gmail.com</span></a>
                    </div>
                        <div className="contact__social-section"><AiFillGithub className="contact__icon"/>
                            <a href="https://github.com/dominikbrzus" className="contact__link">
                            <span className="contact__social-text">github.com/dominikbrzus</span></a>
                        </div>
                        <div className="contact__social-section"><AiFillLinkedin className="contact__icon"/>
                            <a href="https://www.linkedin.com/in/dominik-brzuszczynski/" className="contact__link">
                                <span className="contact__social-text">dominik-brzuszczynski</span></a>
                        </div>
                    </div></div>
                </div>
            </div>
        </div>
    );
}
 
export default Contact;