import React from "react";
import "./navbar.scss";
import {GoThreeBars} from "react-icons/go"
import {GrClose} from "react-icons/gr"

const Navbar = ({openMenu, setOpenMenu, visibleAbout, visibleTech, visiblePortfolio, visibleContact}) => {

    const clickBtn = () => {
        setOpenMenu(!openMenu)
    }
    return (
        <>
        <nav className="nav">
            <div className="nav__main">
                <a href="#" className="nav__logo-link"><p className="nav__logo"><span className="nav__first-word">Websites</span>Design</p></a>
                <button className="nav__btn" onClick={clickBtn}>{openMenu ? <GrClose/> : <GoThreeBars/>}</button>
                <ul className="nav__menu-ul">
                    <li className="nav__item-li"><a href="#about" className={visibleAbout ? "nav__link color" : "nav__link"}>O mnie</a></li>
                    <li className="nav__item-li"><a href="#technology" className={visibleTech ? "nav__link color" : "nav__link"}>Umiejętności</a></li>
                    <li className="nav__item-li"><a href="#portfolio" className={visiblePortfolio ? "nav__link color" : "nav__link"}>Portfolio</a></li>
                    <li className="nav__item-li"><a href="#contact" className={visibleContact ? "nav__link color" : "nav__link"}>Kontakt</a></li>
                </ul>
            </div>
        </nav>
        </>
    );
}
 
export default Navbar;