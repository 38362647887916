import React from "react";
import "./mobile-menu.scss"

const MobileMenu = ({openMenu, setOpenMenu}) => {
    return (
        <>
        <div className={openMenu ? 'mobile-menu open-menu' : 'mobile-menu'}>
            <ul className="mobile-menu__ul flex">
                    <li className="mobile-menu__li">
                        <a href="#about" className="nav__link" onClick={() => setOpenMenu(!openMenu)}>O mnie</a>
                    </li>
                    <li className="mobile-menu__li">
                        <a href="#technology" className="nav__link" onClick={() => setOpenMenu(!openMenu)}>Umiejętności</a>
                    </li>
                    <li className="mobile-menu__li">
                        <a href="#portfolio" className="nav__link" onClick={() => setOpenMenu(!openMenu)}>Portfolio</a>
                    </li>
                    <li className="mobile-menu__li">
                        <a href="#contact" className="nav__link" onClick={() => setOpenMenu(!openMenu)}>Kontakt</a>
                    </li>
                </ul>
        </div>
        </>
    );
}
 
export default MobileMenu;